$primary: #1c1c1c !default; $secondary: #f9fcff !default; $tertiary: #1d6d6d !default; $quaternary: #cb3727 !default; $header_background: #cb3727 !default; $header_primary: #ffffff !default; $highlight: #e6cb37 !default; $danger: #d05454 !default; $success: #71bd9f !default; $love: #c16ad7 !default; $selected: #d2edf3 !default; $hover: #d2edf3 !default;  $public_image_path: "https://ghdev.cnvc.org/images";  @import "common/foundation/variables"; @import "common/foundation/mixins"; .topic-attribution {
  font-weight: bold;
  margin-bottom: 1em;
  margin-top: 1em;
}
.post-attribution {
  color: #8899a6;
  font-size: 12px;
}

button.translated {
  color: var(--tertiary) !important;
}
[data-identifier="discourse-translator_language-switcher"]
  .fk-d-menu__inner-content {
  max-height: 50vh;
}
